<template>
  <div class="manage-unit-types">
    <div class="card px-1 py-3 md-px-3">
      <div class="d-flex align-items-center">
        <h3 class="d-inline-block">Phases</h3>
      </div>
      <div class="row justify-content-end">
        <button
          v-if="canAdd"
          class="btn main"
          :disabled="phases.length < 1"
          @click="openAddUTypeModal"
        >
          <i class="fas fa-plus"></i
          ><span class="d-none sm-d-inline-block ml-1">Add</span>
        </button>
        <button
          :class="{ active: isFilterActive }"
          class="btn toggle ml-1"
          @click="filterToggle"
        >
          <i class="fas fa-filter"></i>
        </button>
      </div>

      <!-- Filter -->
      <filter-panel
        v-model="isFilterActive"
        bordered
        class="filter my-2"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 px-1 mb-2"
            label="Name"
            name="filterUnitTypeName"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
        </div>
      </filter-panel>

      <!-- ============================ Content ============================ -->
      <div class="py-2">
        <!-- Spinner -->
        <div
          v-if="!!isLoading || !!phasesIsLoading"
          class="spinner-container d-flex justify-content-center align-items-center"
        >
          <spinner></spinner>
        </div>
        <!-- Content -->
        <div v-show="!isLoading && !phasesIsLoading">
          <no-data
            v-if="phases.length < 1"
            message="Please add a phase first."
          ></no-data>
          <fd-tabs v-else v-model="phaseTab" @tabChange="selectPhase">
            <fd-tab-window
              v-for="phase in phases"
              :key="phase.id"
              :label="phase.name"
              :name="phase.id"
              class="mt-3"
            >
              <no-data
                v-if="unitTypes.length < 1"
                message="Oops, there is no unit type for this phase."
              ></no-data>

              <div v-else>
                <!-- unit type cards -->
                <div class="row justify-content-start">
                  <unit-type-card
                    v-for="unitType in unitTypes"
                    :key="unitType.id"
                    :unitType="unitType"
                    class="col-12 sm-col-6 lg-col-4"
                    :canEdit="canEdit"
                    :canDelete="canDelete"
                    @delete="deleteUnitType"
                    @edit="openEditUTypeModal"
                    @toDetail="toUnitTypeDetail"
                  ></unit-type-card>
                </div>
              </div>
            </fd-tab-window>
          </fd-tabs>
        </div>

        <!-- pagination -->
        <fd-page
          v-show="unitTypes.length > 0"
          class="text-center pt-2"
          v-model="paginationParams.page"
          :total-row="unitTypesPagination.total"
          :perPage="paginationParams.perPage"
          @page-change="pageChange"
        ></fd-page>
      </div>
    </div>

    <!-- ======================== Modal: Add New ========================= -->
    <modal v-model="addNewUTypeModal.isShown" persistent>
      <add-unit-type
        :type="type"
        :projectId="projectId"
        :propertyCategory="propertyCategory"
        @cancel="addNewUTypeModal.isShown = false"
        @submit="createUnitType"
      ></add-unit-type>
    </modal>
    <!-- ========================= Modal: Edit =========================== -->
    <modal v-model="editUTypeModal.isShown" persistent>
      <edit-unit-type
        :type="type"
        :unitTypeId="editUTypeModal.unitTypeId"
        :propertyCategory="propertyCategory"
        @cancel="editUTypeModal.isShown = false"
        @submit="updateUnitType"
      ></edit-unit-type>
    </modal>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectUnitTypeAPI from "@/modules/Project/api/projectUnitType";

export default {
  components: {
    UnitTypeCard: () =>
      import("@/modules/Project/components/UnitType/UnitTypeCard"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    AddUnitType: () =>
      import("@/modules/Project/components/UnitType/AddUnitType"),
    EditUnitType: () =>
      import("@/modules/Project/components/UnitType/EditUnitType")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: [Number, String]
    },
    propertyCategory: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      unitTypeAPI: new ProjectUnitTypeAPI(this.type),

      isLoading: false,

      phaseTab: "",
      selectedPhaseId: "",
      unitTypes: [],
      unitTypesPagination: {},

      // Filter Related
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      },
      // Pagination
      paginationParams: {
        page: 1,
        perPage: 6
      },
      // Modals
      addNewUTypeModal: {
        isShown: false
      },
      editUTypeModal: {
        unitTypeId: "",
        isShown: false
      }
    };
  },
  computed: {
    phases() {
      return this.$store.getters["projectDetail/getPhases"];
    },
    phasesIsLoading() {
      return this.$store.getters["projectDetail/getPhaseIsLoading"];
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.getPhases()]);
    },

    openAddUTypeModal() {
      this.addNewUTypeModal.isShown = true;
    },

    openEditUTypeModal(id) {
      this.editUTypeModal.unitTypeId = id;
      this.editUTypeModal.isShown = true;
    },

    toUnitTypeDetail(unitTypeId) {
      this.$router.push({
        name: "ManageProjectUnitTypesDetails",
        params: { projectId: this.projectId, id: unitTypeId }
      });
    },
    selectPhase: debounce(
      async function (id) {
        try {
          this.selectedPhaseId = id;
          await this.getUnitTypes();
        } catch (error) {
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "An unexpected error occured. Please try again later."
          });
        }
      },
      300,
      { leading: true }
    ),

    // ===================== Filter related methods =====================
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    async pageChange(info) {
      this.paginationParams.page = info.pageNumber;
      await this.getUnitTypes();
    },
    filterData() {
      this.paginationParams.page = 1;
      this.getUnitTypes();
    },
    clearFilter() {
      if (this.$isFilterEmpty(this.filter)) {
        return;
      } else {
        this.paginationParams.page = 1;
        this.filter = this._.mapValues(this.filter, () => null);
        this.getUnitTypes();
      }
    },
    // ====================== API related methods ======================
    async getPhases() {
      await this.$store.dispatch("projectDetail/refreshPhases", this.projectId);
    },
    async getUnitTypes() {
      try {
        this.isLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.unitTypeAPI.getAllUnitTypes({
          queries: {
            "project:id": this.projectId,
            "projectPhases:id": this.selectedPhaseId,
            ...filteredParam
          },
          page: this.paginationParams.page,
          perPage: this.paginationParams.perPage,
          include: "projectPhases"
        });

        this.unitTypes = this._.cloneDeep(data.data);
        this.unitTypesPagination = this._.cloneDeep(data.meta.pagination);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },

    async createUnitType(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.unitTypeAPI.createUnitType(payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Unit type has been created successfully."
        });
        this.addNewUTypeModal.isShown = false;
        await this.getUnitTypes();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },

    async updateUnitType(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.unitTypeAPI.updateUnitType({
          id: id,
          payload: payload
        });
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Unit type has been updated successfully."
        });

        this.editUTypeModal.isShown = false;
        await this.getUnitTypes();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },

    async deleteUnitType(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.unitTypeAPI.deleteUnitType(id);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Unit type has been deleted successfully."
        });
        await this.getUnitTypes();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-unit-types {
  .spinner-container {
    min-height: 300px;
  }
}
</style>
